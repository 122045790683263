import * as React from "react"
import PDFForm from "../components/PDFForm";

import Seo from "../components/Seo"

export default function pdfTest({  }) {
  const ref = React.createRef();

  return(
    <>
      <Seo title = "How to vote" />
      <PDFForm />
    </>
  )
}